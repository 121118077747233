import { Injectable, inject } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CountryState, CountryStore } from './country.store';

@Injectable({
  providedIn: 'root'
})
export class CountryQuery extends QueryEntity<CountryState> {
  protected override store: CountryStore;

  constructor() {
    const store = inject(CountryStore);

    super(store);
  
    this.store = store;
  }
}
